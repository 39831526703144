import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Helmet from 'react-helmet'
const sesf = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'single-page whitemain'
    }}
/>
  <Layout pageTitle="sesf">
    <section id="main">
      <div id="worktitle">
        <span>
          <h1>Sports Excellence Scholarship Fund</h1>
          <span className="highlight"></span>
        </span>
        <span>Sports Scholarship</span>
      </div>
    </section>

    <section id="work">
      <div className="work">
          <div className="picture workpicture">
		  	<img src={'/img/works/sesf.png'} alt="SESF" />

          </div>

        <div className="seeWork">
          <div className="seeWork__content">
            <div className="icons">
              <img className="picture" src={'/img/single/wordpress-icon.png'} width="139" height="87" alt="Wordpress" />

            </div>
            <a className="button visitwebsite" href="https://sesf.org.au/" target="_blank"  rel="noreferrer"><span className="seemore"><i>&#xe801;</i></span>Visit Website</a>
          </div>
        </div>

      </div>
    </section>

    <section id="single-content">
      <div className="single-content">
        <p>The Sports Excellence Scholarship Fund provides students with the opportunity to break down the barriers that have been placed in front of them, helping them achieve their dreams and build skills for life through the power of sport.</p>
        <p>Collobrating with Involve Group to deliver customised WordPress development responsive for desktop, smartphone and tablet. Customised theme powered by WordPress and Elementor Page Builder plugin.</p>
      </div>

      <div className="project-image">
        <div className="left-col">
          <img className="picture" src={'/img/single/sesf-desktop.jpg'} alt="Desktop View" />
        </div>
        <div className="right-col">
          <img className="picture" src={'/img/single/sesf-mobile.jpg'} alt="Mobile View" />
      </div>
      </div>
    </section>


    <section id="post-pagination">
      <div className="post-pagination">
        <div className="previous">
          <AniLink cover to="/work/promentor" bg="#00ccd4" direction="right" duration={0.76} className="button project prev">Prev Work</AniLink>
          <p className="project-name">Promentor</p>
        </div>
        <div className="next">
          <AniLink cover to="/work/qvm-winter-night-market" bg="#00ccd4" direction="right" duration={0.76} className="button project next">Next Work</AniLink>
          <p className="project-name">QVM Night Market</p>
        </div>
      </div>
    </section>
</Layout>

  </>
  )
}

export default sesf


export const Head = () => (
  <>
    <title>SESF - Tobstar</title>
<meta name="description" content="The Sports Excellence Scholarship Fund provides students with the opportunity to break down the barriers that have been placed in front of them, helping them achieve their dreams and build skills for life through the power of sport." />
  </>
)